module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LDQD9N7YJ4"]},
    },{
      plugin: require('../node_modules/@bonobolabs/gatsby-remark-images-custom-widths/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js"},"remarkPlugins":[[null,{"theme":{"default":"Material Theme"},"inlineCode":{"marker":"•","theme":{"default":"Material Theme"}},"extensions":["/opt/buildhome/repo/vendor/material-theme.vsix"]}]],"gatsbyRemarkPlugins":[{"resolve":"/opt/buildhome/repo/node_modules/@bonobolabs/gatsby-remark-images-custom-widths","id":"c4600f88-3071-50b2-9c5e-f1dc9b18db36","name":"@bonobolabs/gatsby-remark-images-custom-widths","version":"0.0.7","modulePath":"/opt/buildhome/repo/node_modules/@bonobolabs/gatsby-remark-images-custom-widths/index.js","pluginOptions":{"plugins":[],"maxWidth":1200},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/@bonobolabs/gatsby-remark-images-custom-widths/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
